import React, { Fragment, useState } from "react"

import { useForm, SubmitHandler } from "react-hook-form"

import styled from "@emotion/styled"
import tw from "twin.macro"
import InputErrorMessage from "./errorMessage"

const Input = styled.input`
  max-width: 500px;
  ${tw`w-full border-none  placeholder-gray-500 py-4 focus:shadow-lg  focus:ring-0 my-3`};
`

interface IFormData {
  name: string
  email: string
  message: string
}

const ContactForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  console.log(errors)
  // Onsubmit Form
  const onSubmit: SubmitHandler<IFormData> = async data => {
    setLoading(true)
    const result = await fetch("/.netlify/functions/contactus", {
      method: "POST",
      body: JSON.stringify(data),
    })
    setLoading(false)
    setSubmitMessage(true)
    // const res = await result.json()
    // console.log(res)
    reset()
  }

  return (
    <Fragment>
      <div>
        {submitMessage && (
          <div className="bg-green rounded-lg bg-opacity-80 text-white  p-10 w-full">
            <p className="text-xl text-center">
              Your application submitted successfully, we'll contact you
              shortly.
            </p>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              {...register("name", { required: "Please enter your name" })}
              type="text"
              placeholder="Name*"
            />
            <InputErrorMessage message={errors?.name?.message} />
          </div>
          <div>
            <Input
              {...register("email", {
                required: "Please enter your email",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
              placeholder="Email*"
              type={"text"}
            />
            <InputErrorMessage message={errors?.email?.message} />
          </div>
          <div>
            <Input
              {...register("phoneNumber", {
                required: "Please enter your phone number",
                minLength: {
                  value: 5,
                  message: "Minimum length is 8",
                },
                maxLength: {
                  value: 20,
                  message: "Minimum length is 20",
                },
              })}
              type="number"
              placeholder="Phone* eg. 0213456789"
            />
            <InputErrorMessage message={errors?.phoneNumber?.message} />
          </div>
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`capitalize w-full flex justify-center items-center text-lg py-4 mt-2 text-white  transition duration-300 ${
                isLoading
                  ? "bg-pink-400 cursor-not-allowed"
                  : "bg-primary cursor-pointer  hover:text-primary hover:bg-white"
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <></>
              )}
              LET'S TALK
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default ContactForm
