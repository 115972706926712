import { graphql, useStaticQuery } from "gatsby"

export const getTestinomailData = () => {
    const { testinomialData } = useStaticQuery(query)
    return testinomialData
}


export const query = graphql`
{
    testinomialData:contentfulTestimonialSection {
      backgroundImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      review {
        author
        text {
          text
        }
      }
    }
  }
  
`
