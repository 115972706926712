import React, { Fragment, FC } from "react"

type props = {
  children: React.ReactChild
  mt?: boolean
  mx?: boolean
  mtSmall?: boolean
  py?: boolean
}

const Wrapper: FC<props> = ({ children, mt, mx, mtSmall, py }) => {
  return (
    <Fragment>
      <div
        className={`mx-auto ${mx ? "max-w-screen-2xl px-3" : ""} ${
          mt ? "mt-24" : "" || mtSmall ? "mt-16" : "" || py ? "py-24" : ""
        }   w-full`}
      >
        {children}
      </div>
    </Fragment>
  )
}

export default Wrapper
