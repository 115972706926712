import React, { Fragment, FC, useState } from "react"
// Icons
import { MenuOutlined } from "@ant-design/icons"
import { motion, useViewportScroll } from "framer-motion"
import MobileNabar from "./mobileNabar"

const Navbar: FC = () => {
  /** this hook gets the scroll y-axis **/
  const { scrollY } = useViewportScroll()
  /** this hook manages state **/
  const [hidden, setHidden] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  /** this onUpdate function will be called in the `scrollY.onChange` callback **/
  function update() {
    if (scrollY["current"] < 100) {
      setHidden(false)
    } else if (
      scrollY["current"] > 100 &&
      scrollY["current"] > scrollY["prev"]
    ) {
      setHidden(true)
    }
  }
  /** update the onChange callback to call for `update()` **/
  React.useEffect(() => {
    return scrollY.onChange(() => update())
  })

  /** add this const **/
  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: {
      background: "transparent",
    },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: {
      background: "#191c23",
    },
  }

  return (
    <Fragment>
      <motion.nav
        variants={variants}
        animate={hidden ? "hidden" : "visible"}
        // transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
        className="px-8 flex justify-between items-center fixed w-full z-20 transition duration-300 "
      >
        <div>
          <h2 className="font-semibold text-white">Freelancer</h2>
        </div>
        <div>
          <MenuOutlined
            onClick={() => setIsOpen(true)}
            className="text-primary text-3xl cursor-pointer"
          />
        </div>
        <div className="fixed">
          <MobileNabar setOpen={setIsOpen} isOpen={isOpen} />
        </div>
      </motion.nav>
    </Fragment>
  )
}

export default Navbar
