import React, { FC } from "react"

interface IProps {
  message: string
}

const InputErrorMessage: FC<IProps> = ({ message }) => {
  return (
    <div>
      <p className="text-red-600">{message}</p>
    </div>
  )
}

export default InputErrorMessage
