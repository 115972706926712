import React, { Fragment } from "react"
import Testonomial from "../shared/testonomial"
import HeaderTagline from "../shared/headerTagling"
import SectionHeader from "../shared/sectionHeader"
import Underline from "../shared/underline"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Wrapper from "../shared/wrapper"
import ContactForm from "../shared/contactForm"
// Icons
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
// Services
import { getTContactDetails } from "../../services/contactDetails"
// Interfaces
import { IContactDetails } from "../../interfaces/contactDetails"

const TextWrapper = styled.section`
  max-width: 800px;
  ${tw`py-5 mt-4 text-center mx-auto  w-full px-4`};
`
const FormWrapper = styled.section`
  max-width: 500px;
  ${tw`mx-auto pt-12`};
`

const Footer = () => {
  const data: IContactDetails = getTContactDetails()

  return (
    <Fragment>
      <div>
        <Testonomial />
        <div className="bg-secondaryWhite">
          <Wrapper mx={true} py={true}>
            <>
              <div className="flex flex-col items-center">
                <SectionHeader
                  color="lightBlack"
                  text={data.contactSection.header}
                />
                <HeaderTagline
                  classsName="text-secondaryBlack"
                  text={data.contactSection.tagline as string}
                />
                <Underline color="secondaryBlack" />
              </div>
              {/* <TextWrapper>
                <p className="text-secondaryBlack">
                  {data.contactSection.tagline}
                </p>
              </TextWrapper> */}
              <FormWrapper>
                <ContactForm />
              </FormWrapper>
            </>
          </Wrapper>
        </div>
        <div className="bg-lightBlack">
          <Wrapper py={true} mx={true}>
            <div className="flex flex-col items-center">
              <h2>Freelancer</h2>
              <p className="text-DarkWhite">
                © Copyright 2012 - 2021 |{" "}
                <span className="text-white"> GraphixVilla</span>
              </p>
              <div className="flex justify-center mt-7">
                <a href="" className="p-2 mx-1 bg-white text-lightBlack">
                  <FaFacebookF />
                </a>
                <a href="" className="p-2 mx-1 bg-white text-lightBlack">
                  <FaInstagram />
                </a>
                <a href="" className="p-2 mx-1 bg-white text-lightBlack">
                  <FaTwitter />
                </a>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </Fragment>
  )
}

export default Footer
