import React, { Fragment, FC } from "react"

interface IProps {
  text: string
  color?: string
}
const SectionHeader: FC<IProps> = ({ text, color }) => {
  return (
    <Fragment>
      <h2 className={`capitalize center text-${color}`}>{text}</h2>
    </Fragment>
  )
}

export default SectionHeader
