import { graphql, useStaticQuery } from "gatsby"

export const getTContactDetails = () => {
    const { contactDetails } = useStaticQuery(query)
    return contactDetails
}


export const query = graphql`
{
    contactDetails: contentfulContactDetails {
      email
      contactSection {
        header
        description {
          description
        }
        tagline
      }
    }
  }
`
