import React, { Fragment, FC } from "react"
import Navbar from "./navbar"
// import MobileNabar from "./mobileNabar"
import Footer from "./footer"


const Layout: FC = ({ children }) => {

  return (
    <Fragment>
      <div className="overflow-x-hidden">
        <Navbar />
        {children}
        <Footer />
      </div>
    </Fragment>
  )
}

export default Layout
