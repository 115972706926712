import { motion } from "framer-motion"
import React, { FC, Fragment } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { IoClose } from "react-icons/io5"
import { Link } from "gatsby"
interface IProps {
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNav = styled(motion.nav)`
  ${tw`w-full h-screen fixed left-0 top-0 bg-black hidden opacity-0`};
`
const variants = {
  open: { opacity: 1, display: "block" },
  closed: { opacity: 0, display: "none" },
}

const MobileNabar: FC<IProps> = ({ isOpen, setOpen }) => {
  return (
    <Fragment>
      <MobileNav animate={isOpen ? "open" : "closed"} variants={variants}>
        <IoClose
          onClick={() => setOpen(false)}
          className="text-white hover:text-primary transition duration-300 cursor-pointer right-0 absolute m-8 text-5xl"
        />
        <div className="flex w-full h-full justify-center items-center">
          <ul className="flex flex-col items-center">
            <Link
              className="text-white mt-12  hover:text-primary transition duration-300"
              to="/"
              activeStyle={{ color: "#fa225b" }}
            >
              <h3> Home</h3>
            </Link>
            <Link
              className="text-white mt-12  hover:text-primary transition duration-300"
              to="/work"
              activeStyle={{ color: "#fa225b" }}
            >
              <h3>Work</h3>
            </Link>
            <Link
              className="text-white mt-12  hover:text-primary transition duration-300"
              to="/contact"
              activeStyle={{ color: "#fa225b" }}
            >
              <h3>Let's Talk</h3>
            </Link>
          </ul>
        </div>
      </MobileNav>
    </Fragment>
  )
}

export default MobileNabar
