import React, { FC, Fragment } from "react"

interface IProps {
  color?: string
}
const Underline: FC<IProps> = ({ color = "white" }) => {
  return (
    <Fragment>
      <div className={`w-52 bg-${color} pt-1 mt-8 `}></div>
    </Fragment>
  )
}

export default Underline
