import React, { FC, Fragment } from "react"

interface IProps {
  text: string
  classsName?: string
}
const HeaderTagline: FC<IProps> = ({ text, classsName }) => {
  return (
    <Fragment>
      <h5 className={` uppercase ${classsName} `}>{text}</h5>
    </Fragment>
  )
}

export default HeaderTagline
