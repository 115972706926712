import React, { Fragment, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Slider from "react-slick"
import Wrapper from "./wrapper"
import { ImQuotesLeft } from "react-icons/im"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
// Interfaces
import { ITestinomialSection } from "../../interfaces/testinomial"
// Datasource
import { getTestinomailData } from "../../services/layout"

const SliderWrapper = styled.section`
  max-width: 900px;
  ${tw`overflow-x-hidden -mt-8`}
  .slick-dots {
    ${tw`flex flex-col justify-center items-center`}
  }
  .slick-dots li {
    color: white;
    ${tw`bg-transparent border rounded-full mx-3 w-4 h-4`}
  }
  .slick-dots li.slick-active {
    ${tw`bg-white `}
  }
  .slick-dots li button::before {
    ${tw` text-transparent `}
  }
  .slick-dots li.slick-active button::before {
    ${tw` text-transparent `}
  }
`
const TestoWrapper = styled.section`
  ${tw`py-5  text-center text-white mx-auto  w-full px-4`};
`
const BgImageWrapper = styled.section`
  clip-path: polygon(0 0, 100% 0, 100% 84%, 0% 100%);
  ${tw`w-full`};
`

const Testonomial = () => {
  const data: ITestinomialSection = getTestinomailData()


  const img = getImage(data.backgroundImage)
  const bgImg = convertToBgImage(img)



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots ",
    appendDots: (dots: any) => (
      <div
        style={{
          color: "white",
          position: "absolute",
          bottom: "5px",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),

    // customPaging: function (i: any) {
    //   return (
    //     <div className="p-1 flex justify-center rounded-full bg-white mx-2 z-10"></div>
    //   )
    // },
  }
  return (
    <Fragment>
      <div className="w-full bg-secondaryWhite">
        <BgImageWrapper>
          <BackgroundImage {...bgImg} className="bg-center bg-fixed">
            <Wrapper mx={true} py={true}>
              <div className="flex justify-center items-center flex-col">
                <ImQuotesLeft className="text-9xl text-center text-primary" />
                <SliderWrapper>
                  <Slider className="flex justify-center py-10 " {...settings}>
                    {data.review.map(testo => (
                      <TestoWrapper>
                        <p>"{testo.text.text}"</p>
                        <h5 className="font-bold mt-8">{testo.author}</h5>
                      </TestoWrapper>
                    ))}
                  </Slider>
                </SliderWrapper>
              </div>
            </Wrapper>
          </BackgroundImage>
        </BgImageWrapper>
      </div>
    </Fragment>
  )
}

export default Testonomial
